<template>
    <div class="product-content-filters">
        <h2 class="product-content-filters__header">Filters</h2>
        <div class="product-content-filters__section">
            <mercur-input v-model="textFilter" @input="emit" placeholder="Search" data-test="productContentOverviewSearch">
                <template slot="suffix"><i class="fas fa-search"></i></template>
            </mercur-input>
        </div>
        <div class="product-content-filters__section">
            <div class="product-content-filters__subheader">Content</div>
            <mercur-radio class="d-inline-block mr-3" v-model="contentFilter" @change="emit" :value="null">All</mercur-radio>
            <mercur-radio class="d-inline-block mr-3" v-model="contentFilter" @change="emit" value="WITH_CONTENT">With content</mercur-radio>
            <mercur-radio class="d-inline-block" v-model="contentFilter" @change="emit" value="WITHOUT_CONTENT">Without content</mercur-radio>
        </div>
        <div class="product-content-filters__section">
            <div class="product-content-filters__subheader">Categories</div>
            <div v-if="filters.categories === null">
                loading categories..
            </div>
            <div v-if="filters.categories === false">
                fetching categories failed.
            </div>
            <mercur-radio @change="emit" v-model="categoriesFilter" :value="null">All</mercur-radio>
            <div v-for="(filter, key) in filters.categories" :key="key">
                <mercur-radio @change="emit" v-model="categoriesFilter" :value="filter.productCategoryId">{{ filter.categoryName }}</mercur-radio>
            </div>
        </div>

<!--        <div class="product-content-filters__section">-->
<!--            <div class="product-content-filters__subheader">Status</div>-->
<!--            <div v-for="filter in filters.states" :key="filter.value">-->
<!--                <mercur-radio v-model="statusFilter" @change="emit" :value="filter.value" class="filter-checkbox">{{ filter.title }}</mercur-radio>-->
<!--            </div>-->
<!--        </div>-->

        <div class="product-content-filters__section">
            <div class="product-content-filters__subheader"></div>
            <mercur-checkbox v-model="isNewFilter" @change="emit" :value="true" class="my-3">Only new</mercur-checkbox>
        </div>
    </div>
</template>
<script>
import CONFIG from '@root/config'
import { debounce } from 'debounce'

export default {
    name: 'ProductContentFilters',
    props: {
        value: {},
    },
    data () {
        return {
            contentFilter: null,
            textFilter: null,
            categoriesFilter: null,
            statusFilter: null,
            isNewFilter: false,
        }
    },
    computed: {
        filters () {
            return {
                categories: this.$store.state.productContent.categories,
                states: CONFIG.PRODUCT_CONTENT_MANAGEMENT.FILTERS.STATES,
            }
        },
    },
    methods: {
        emit: debounce(function () {
            const filterModel = {}

            if (this.contentFilter !== null) {
                filterModel.contentHash = {
                    type: 'equals',
                    filter: this.contentFilter,
                }
            }

            if (this.textFilter) {
                filterModel.productName = {
                    type: 'contains',
                    filter: this.textFilter,
                }
            }

            if (this.categoriesFilter) {
                filterModel.productCategoryId = {
                    type: 'equals',
                    filter: this.categoriesFilter,
                }
            }

            if (this.statusFilter) {
                filterModel.productStatus = {
                    type: 'equals',
                    filter: this.statusFilter,
                }
            }

            if (this.isNewFilter) {
                filterModel.productIsNew = {
                    type: 'equals',
                    filter: true,
                }
            }

            this.$emit('input', filterModel)
        }, 500),
    },
}
</script>

<style lang="scss" scoped>
    .filter-checkbox {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .product-content-filters {
        &__header {
            font-size: 20px;
            margin-top: 20px;
            margin-bottom: 0;
            font-weight: 500;
        }
        &__section {
            margin-top: 20px;
            padding-right: 10px;
        }
        &__subheader {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
        }
    }
</style>
