<template>
    <div class="fill full-height-layout">
        <h2 class="title">Product content management</h2>
        <div class="row no-gutters border-top fill">
            <div class="col-3 fhf h-100 u-z-index-2">
                <product-content-filters v-model="filterModel" @input="setFilterModel"></product-content-filters>
            </div>
            <div class="row col-9 fill height-100 pl-2">
                <data-table
                    class="height-100 border"
                    ref="grid"
                    :options="gridOptions"
                    :url="gridUrl"
                ></data-table>
            </div>
        </div>
    </div>
</template>

<script>
import DataTable from '../../components/DataTable'
import CONFIG from '@root/config'
import ProductContentFilters from '../../components/product_content_management/ProductContentFilters'

export default {
    name: 'ProductContentOverview',
    components: { ProductContentFilters, DataTable },
    data () {
        return {
            filterModel: null,
            gridUrl: CONFIG.API.ROUTES.PRODUCT_CONTENT.PRODUCTS.LIST,
            gridOptions: {
                columns: {
                    'Product name': {
                        field: 'productName',
                        sortable: true,
                    },
                    'Product content name': {
                        field: 'productContent.productName',
                    },
                    'Category': {
                        field: 'productCategoryId',
                        valueGetter: ({ data }) => {
                            if (!this.$store.state.productContent.categories) {
                                return ''
                            }
                            return this.$store.state.productContent.categories.find(({ productCategoryId }) => productCategoryId === data.productCategoryId).categoryName
                        },
                    },
                    'Has content': {
                        field: 'contentHash',
                        sortable: true,
                        valueFormatter ({ value }) {
                            return value ? 'yes' : 'no'
                        },
                    },
                    'Is new': {
                        field: 'productIsNew',
                        sortable: true,
                        valueFormatter ({ value }) {
                            return value === null ? 'no' : 'yes'
                        },
                        tooltipField: 'productIsNew',

                    },
                    'Created': {
                        field: 'dateCreated',
                        sortable: true,
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                    'Updated': {
                        field: 'dateUpdated',
                        sortable: true,
                        valueFormatter: ({ value }) => {
                            return this.$options.filters.asDatetime(value)
                        },
                    },
                },
                actions: [
                    {
                        text: 'Edit',
                        icon: 'fas fa-edit',
                        to: ({ data }) => {
                            return {
                                name: 'ProductContentMarketplaceProductView',
                                params: {
                                    marketplaceProductId: data.marketplaceProductId,
                                },
                            }
                        },
                        disabled: () => {
                            return this.loading
                        },
                        attr: {
                            'data-test': 'productContentManagementOverviewEditProduct',
                        },
                    },
                ],
                enableBrowserTooltips: true,
            },
        }
    },
    methods: {
        setFilterModel (filterModel) {
            this.$refs.grid.gridOptions.api.setFilterModel(filterModel)
        },
    },
}
</script>
<style lang="scss" scoped>
.title {
    font-size: 32px;
    font-weight: 400;
}
</style>
